<script>
import { required } from 'vuelidate/lib/validators';
import { inArray } from '@shared/config/vuelidate';
import constants from '@shared/config/constants';
import Stripe from '@shared/services/Stripe';
import utilsMixin from '@shared/mixins/utils';
import APIPayment from '@app/services/API/Payment';
import APIUser from '@app/services/API/User';
import StripeElementsSplitted from '@shared/components/StripeElementsSplitted.vue';

export default {
  mixins: [utilsMixin],
  components: { StripeElementsSplitted },
  props: {
    user: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      isLoading: false,
      isFetching: false,
      proration: null,
      subscription: null,
      paymentMethods: null,
      stripeError: null,
      stripeIsCompleted: false,
      invite: {
        user_uuid: this.user ? this.user.uuid : undefined,
        email: this.user ? this.user.email : '',
        role: this.user ? this.user.role : '',
      },
    };
  },
  computed: {
    authUser() {
      return this.$store.getters['auth/user'];
    },
    authStore() {
      return this.$store.getters['auth/store'];
    },
    subscriptionFrequency() {
      if (!this.subscription.store_subscription) {
        return 'MONTHLY';
      }

      return this.subscription.store_subscription.frequency;
    },
    canInviteForFree() {
      return (this.authStore.user_account_count < this.$store.getters['auth/nbSeatsAvailable']);
    },
    hasPaymentForm() {
      return false;
    },
    discount() {
      if (!this.subscription.store_subscription) {
        return 0;
      }

      return this.subscription.additional_data.percent_off;
    },
    price() {
      const discount = 1 - this.discount / 100;

      return this.$constants.PRICES[this.subscriptionFrequency].USER_ACCOUNT * discount;
    },
  },
  validations: {
    invite: {
      role: {
        required,
        inArray: inArray(constants.roles),
      },
    },
  },
  created() {
    this.getInitialData();
  },
  methods: {
    getSubscriptionProration() {
      if (this.canInviteForFree) {
        return null;
      }

      return APIPayment.getSubscriptionProration({
        type: 'USER_ACCOUNT',
        frequency: this.subscriptionFrequency,
      })
        .then(({ data }) => (this.proration = data));
    },
    getSubscriptions() {
      return APIPayment.getSubscriptions()
        .then(({ data }) => (this.subscription = data));
    },
    getPaymentMethods() {
      return APIPayment.getPaymentMethods()
        .then(({ data }) => (this.paymentMethods = data));
    },
    getInitialData() {
      this.isFetching = true;
      return Promise.all([
        this.getSubscriptions(),
        this.getPaymentMethods(),
      ])
        .then(() => this.getSubscriptionProration())
        .finally(() => (this.isFetching = false));
    },
    handle() {
      const needsStripeComleted = (this.hasPaymentForm && !this.stripeIsCompleted);

      if (needsStripeComleted) {
        return;
      }

      this.isLoading = true;
      const invite = { ...this.invite };
      let promise;

      if (this.canInviteForFree) {
        promise = APIUser.invite(invite);
      } else {
        this.$buefy.dialog.alert({
          title: 'Opération impossible',
          message: `
          Veuillez contacter votre responsable de compte pour effectuer cette opération
          car vous avez atteint le nombre maximum de membres prévu dans votre offre.
        `,
        });

        this.isLoading = false;
        return;
      }

      promise.then(() => this.$emit('done', invite))
        .finally(() => (this.isLoading = false));
    },
    handleSubscription({
      data: {
        user, additional_data, store_subscription, subscription,
      },
    }) {
      this.$store.commit('auth/update', user);

      const { latest_invoice } = subscription;
      const { payment_intent } = latest_invoice;

      if (payment_intent) {
        const { client_secret, status } = payment_intent;
        console.log('status', status, client_secret, payment_intent);

        if (status === 'requires_action' || status === 'requires_confirmation') {
          let confirmCardPayment;

          if (this.paymentMethods.length) {
            confirmCardPayment = Stripe.init(this.$env.stripePK)
              .then(() => Stripe.confirmCardPayment(client_secret, {
                payment_method: payment_intent.payment_method,
              }));
          } else {
            confirmCardPayment = this.$refs.card.confirmCardPayment(client_secret);
          }

          return confirmCardPayment.then(() => {
            this.$store.commit('auth/update', user);
            this.subscription = { additional_data, store_subscription };
            this.handleSubscriptionSuccess();
          });
        }
      }

      this.subscription = { additional_data, store_subscription };
      return this.handleSubscriptionSuccess();
    },
    handleSubscriptionSuccess() {
      this.$showMessage.success();
    },
  },
};
</script>

<template>
  <form class="box" @submit.prevent="dataIsValid(isLoading) && handle()">
    <b-skeleton v-if="isFetching" height="400" />
    <div v-else class="columns is-multiline">
      <div class="column is-12">
        <h2 class="title is-4">
          <template v-if="user">
            Réactiver le membre
          </template>
          <template v-else>
            Inviter un membre
          </template>
        </h2>

        <AppI18N
          v-if="!user && canInviteForFree"
          tag="p"
          path="users.nb_seats_left"
          :count="$store.getters['auth/nbSeatsLeft']"
        />

        <p v-if="discount > 0 && !canInviteForFree">
          Ce compte membre supplémentaire vous est facturé
          <span
            v-t="{
              path: `frequencies.${subscriptionFrequency}`,
              args: { price },
            }"
          /> (TTC) car vous bénéficiez d'une réduction de {{ discount }}%.
        </p>

        <p v-if="proration && proration.prorated_amount" class="mt-3">
          Pour
          <template v-if="user">
            réactiver ce membre,
          </template>
          <template v-else>
            ce membre supplémentaire,
          </template>
          vous serez facturé immédiatement de {{ proration.prorated_amount | formatPrice }}
          TTC (prorata de votre période de facturation en cours),
          puis de {{ price }} € TTC
          à chaque date anniversaire de votre abonnement (à partir du
          {{ proration.next_payment_date | momentFromUTC | moment('DD/MM/YY') }}).
        </p>
      </div>
      <div class="column is-12">
        <b-field label="Adresse email">
          <b-input type="email" v-model="invite.email" required :disabled="!!user" />
        </b-field>
      </div>
      <div class="column is-12">
        <b-field label="Rôle" v-bind="$getErrorProps($v.invite.role, ['required', 'inArray'])">
          <b-dropdown v-model="invite.role" expanded append-to-body aria-role="list">
            <template #trigger>
              <b-button class="is-justify-content-space-between" icon-right="chevron-down" expanded>
                <template v-if="invite.role">
                  <span v-t="`roles.${invite.role}`" />
                </template>
                <template v-else>
                  Choisissez le rôle
                </template>
              </b-button>
            </template>
            <b-dropdown-item class="wspace-normal" value="ADMIN" aria-role="listitem">
              <h3 class="has-text-weight-bold">
                Administrateur
              </h3>
              <p>Peut tout faire sauf supprimer l'espace de formation.</p>
            </b-dropdown-item>
            <b-dropdown-item class="wspace-normal" value="TEACHER" aria-role="listitem">
              <h3 class="has-text-weight-bold">
                Formateur
              </h3>
              <p>Peut créer et modifier les formations auxquelles il/elle est assigné(e).</p>
            </b-dropdown-item>
            <b-dropdown-item class="wspace-normal" value="ANALYST" aria-role="listitem">
              <h3 class="has-text-weight-bold">
                Analyste
              </h3>
              <p>Peut tout consulter, mais ne peut rien créer, modifier ou supprimer.</p>
            </b-dropdown-item>
          </b-dropdown>
        </b-field>
      </div>
      <div v-if="hasPaymentForm" class="column is-12">
        <b-field
          label="Renseignez votre carte bancaire"
          :type="{'is-danger': stripeError && stripeError.message}"
          :message="stripeError && stripeError.message"
        >
          <StripeElementsSplitted
            ref="card"
            @error="stripeError = $event"
            @complete="stripeIsCompleted = $event"
          />
        </b-field>
      </div>
      <div class="column is-12 has-text-right">
        <b-button type="is-text" @click="$emit('cancel')">
          Annuler
        </b-button>
        <b-button
          type="is-primary"
          native-type="submit"
          :loading="isLoading"
          :disabled="(hasPaymentForm && !stripeIsCompleted)">
          <template v-if="user">
            Réactiver le membre
          </template>
          <template v-else>
            Ajouter le membre
          </template>
        </b-button>
      </div>
    </div>
  </form>
</template>
